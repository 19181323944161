// extracted by mini-css-extract-plugin
export var api = "Posts-module--api--pMZFS";
export var blogContainer = "Posts-module--blogContainer--c8yYo";
export var blogDate = "Posts-module--blogDate--kNC24";
export var blogTags = "Posts-module--blogTags--utx2W";
export var blogTitle = "Posts-module--blogTitle--wBCIz";
export var books = "Posts-module--books--bG+aF";
export var communication = "Posts-module--communication--A2-1-";
export var dateContainer = "Posts-module--dateContainer--1YVf5";
export var deployment = "Posts-module--deployment--5Gq0R";
export var evergreen = "Posts-module--evergreen--QP0Yq";
export var fundamentals = "Posts-module--fundamentals--fwB4V";
export var hacktoberfest = "Posts-module--hacktoberfest--rmaeE";
export var interview = "Posts-module--interview--D8yjB";
export var javascript = "Posts-module--javascript--hORA-";
export var life = "Posts-module--life--jKfCI";
export var linkContainer = "Posts-module--linkContainer--vNMrY";
export var node = "Posts-module--node--8Ccyv";
export var notes = "Posts-module--notes--h97GU";
export var onboarding = "Posts-module--onboarding--vkOc7";
export var openSource = "Posts-module--open-source--BWkeS";
export var productivity = "Posts-module--productivity--eTMNZ";
export var project = "Posts-module--project--YXg49";
export var react = "Posts-module--react--KQrzr";
export var snippets = "Posts-module--snippets--yjjqS";
export var tag = "Posts-module--tag--PMrP3";
export var template = "Posts-module--template--vXFn0";
export var titleDateContainer = "Posts-module--titleDateContainer--cDuoY";
export var titleTagContainer = "Posts-module--titleTagContainer--6oZkd";