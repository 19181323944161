import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { getCategoriesAndTags } from '../../utils/hooks/getCategoriesAndTags'

import * as styles from './CategoriesBar.module.css'

export const CategoriesBar = () => {
  const data = getCategoriesAndTags()
  const { categories } = data
  const blogsByCategory = categories.group.filter(
    category => category.name !== 'highlights'
  )

  return (
    <div>
      <div className={styles.categoryContainer}>
        {blogsByCategory.map(category => {
          return (
            <Link
              key={category.name}
              to={`/categories/${category.name}`}
              className={styles.category}
              activeClassName={styles.activeCategory}
            >
              <div className="name">{category.name}</div>{' '}
            </Link>
          )
        })}
      </div>
    </div>
  )
}

CategoriesBar.propTypes = {
  children: PropTypes.node,
}

export default CategoriesBar
