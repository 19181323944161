import { graphql, useStaticQuery } from 'gatsby'

export const getCategoriesAndTags = () => {
  return useStaticQuery(graphql`
    query TaxonomyQuery {
      tags: allMarkdownRemark {
        group(field: frontmatter___tags) {
          name: fieldValue
          totalCount
        }
      }

      categories: allMarkdownRemark {
        group(field: frontmatter___category) {
          name: fieldValue
          totalCount
        }
      }
    }
  `)
}
