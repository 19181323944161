import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'

import * as styles from './Posts.module.css'

const Posts = ({ frontmatter }) => {
  const dateArr = frontmatter.date.split(' ')
  dateArr[0] = dateArr[0].slice(0, 3)
  dateArr[1] = parseInt(dateArr[1], 10)
  const formattedDate = `${dateArr[0]} ${dateArr[1]}, ${dateArr[2]}`
  const tags = frontmatter.tags
  return (
    <div>
      <Link to={frontmatter.path} className={styles.linkContainer}>
        <div className={styles.blogContainer}>
          <div className={styles.titleDateContainer}>
            <div className={styles.titleTagContainer}>
              <p className={styles.blogTitle}>{frontmatter.title}</p>
            </div>
            <div className={styles.dateContainer}>
              <p className={styles.blogDate}>{formattedDate}</p>
            </div>
          </div>
          <div className={styles.blogTags}>
            {tags.map(tag => (
              <p key={formattedDate} className={`${styles.tag} ${styles[tag]}`}>
                {tag}
              </p>
            ))}
          </div>
        </div>
      </Link>
    </div>
  )
}

Posts.propTypes = {
  frontmatter: PropTypes.shape({
    title: PropTypes.string,
    path: PropTypes.string,
    date: PropTypes.string,
    tags: PropTypes.string,
  }),
}

export default Posts
